export default defineNuxtRouteMiddleware(async (to) => {
  // NOTE: usePublicArticleNewsHelpers の内部で useCategories が呼び出されているのが原因で、以下のエラーになってしまう。
  // 事前に呼び出しておくとエラーにならないようなので、ここでuseCategoriesを呼び出しておく。
  // (composablesが内包しているcomposablesが非同期処理を行ってるとエラーになる？バグなのか仕様なのかは不明)
  // [nuxt] A composable that requires access to the Nuxt instance was called outside of a plugin, Nuxt hook, Nuxt middleware, or Vue setup function. This is probably not a Nuxt bug. Find out more at `https://nuxt.com/docs/guide/concepts/auto-imports#vue-and-nuxt-composables`.
  await useCategories();

  const { configureBreadcrumbs, categoryId, categoryName } =
    await usePublicArticleNewsHelpers(to);

  // 存在しないカテゴリーだった場合、URL queryを削除する
  if (categoryId.value && !categoryName.value) {
    return await navigateTo(to.path);
  }

  configureBreadcrumbs();
});
